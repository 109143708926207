<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="11"
      >
        <div :class="[`text-h4`]"> {{ $route.params.id }} </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col>
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              link
              @click="$router.go(-1)"
            >
              <v-list-item-icon>
                <v-icon>mdi-page-previous-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Back</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="$router.push({ path: `/end-point/${$route.params.id}/edit` })"
            >
              <!-- @click="$router.push({name: 'EndPointEdit', params: {id: $route.params.id}})" -->
              <v-list-item-icon>
                <v-icon>mdi-file-document-edit-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="isCancel = true, dialogType = 'delete'"
            >
              <v-list-item-icon>
                <v-icon>mdi-delete-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="reloadEndpoint()"
            >
              <v-list-item-icon>
                <v-icon>mdi-reload</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Reload</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-divider class="mt-4"></v-divider>
    <loading-endpoint-detail v-if="!isFetch" />
    <v-container v-if="isFetch">
      <v-row dense>
        <v-col cols="12">
          <v-card
            elevation="2"
          >
            <v-card-title>Incoming Request</v-card-title>
            <v-card-subtitle>
              Req/min
            </v-card-subtitle>
            <v-card-text>
              <div>
                <line-chart :chart-data="lineData" :options="chartOptions"></line-chart>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-tabs  v-model="tab">
            <v-tab>Request History</v-tab>
            <v-tab>Request Result</v-tab>
            <!-- <v-tab>Logger</v-tab> -->
            <v-tab>Exception</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-data-table dense show-expand
                :headers="headers"
                :items="histories"
                :items-per-page="10"
                class="elevation-1"
                :expanded.sync="expanded"
                item-key="request_id"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Request History</v-toolbar-title>
                  </v-toolbar>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    {{ item }}
                  </td>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table dense
                :headers="resultHeaders"
                :items="results"
                :items-per-page="10"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Request History</v-toolbar-title>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-tab-item>
            <!-- <v-tab-item>
              <v-data-table dense
                :headers="logHeaders"
                :items="logs"
                :items-per-page="10"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Log</v-toolbar-title>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-tab-item> -->
            <v-tab-item>
              <v-data-table dense
                :headers="exceptionHeaders"
                :items="exceptions"
                :items-per-page="10"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Exception</v-toolbar-title>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <confirm-dialog v-model="isCancel" :type="dialogType" title="end-point" @confirm="confirmDialog()" />
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/services/api'

export default {
  name: 'EndPointDetail',
  components: {
    ConfirmDialog: () => import('@/components/ConfirmDialog'),
    LoadingEndpointDetail: () => import('@/components/LoadingEndpointDetail.vue'),
    LineChart: () => import('@/components/LineChart.js')
  },
  data: () => ({
    isCancel: false,
    isValid: true,
    dialogType: 'cancel',
    isJson: false,
    isFetch: false,
    test: {},
    id: 0,
    tab: null,
    expanded: [],
    headers: [
      {
        text: 'Timestamp',
        value: 'timestamp'
      },
      {
        text: 'Method',
        value: 'method'
      },
      {
        text: 'Data',
        value: 'data'
      }
    ],
    histories: [],
    results: [],
    resultHeaders: [
      {
        text: 'Timestamp',
        value: 'timestamp'
      },
      {
        text: 'Task',
        value: 'task'
      },
      {
        text: 'Result',
        value: 'result'
      }
    ],
    logs: [],
    logHeaders: [
      {
        text: 'Timestamp',
        value: 'at'
      },
      {
        text: 'Args',
        value: 'args'
      },
      {
        text: 'Params',
        value: 'params'
      },
      {
        text: 'Payload',
        value: 'payload'
      }
    ],
    exceptions: [],
    exceptionHeaders: [
      {
        text: 'Timestamp',
        value: 'timestamp'
      },
      {
        text: 'Task',
        value: 'task'
      },
      {
        text: 'Result',
        value: 'result'
      }
    ],
    requestMetric: [],
    chartOptions: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        y: {
          title: {
            display: true
          }
        }
      },
      elements: {
        line: {
          fill: false,
          borderColor: '#FF5733'
        },
        point: {
          radius: 0
        }
      }
    }
  }),
  methods: {
    fetchEndPointHistory () {
      api.get(`/api/services/${this.fiwareService}/endpoints/${this.$route.params.id.split(/:/g).pop()}/history`, null, null, this.$store.state.tokenRPT).then((response) => {
        if (response.status === 200) {
          let dtf = new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium', timeStyle: 'long' })
          response.json().then((json) => {
            console.log(json)
            this.histories = json.map((j) => {
              const jParse = JSON.parse(j)
              return {
                request_id: jParse.request_id,
                timestamp: dtf.format(new Date(jParse.timestamp)),
                method: jParse.method,
                headers: JSON.stringify(jParse.headers),
                query_string: jParse.query_string,
                data: JSON.stringify(jParse.data)
              }
            })
          })
        }
        this.isFetch = true
      })
    },
    fetchEndPointResult () {
      api.get(`/api/services/${this.fiwareService}/endpoints/${this.$route.params.id.split(/:/g).pop()}/result`, null, null, this.$store.state.tokenRPT).then((response) => {
        if (response.status === 200) {
          let dtf = new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium', timeStyle: 'long' })
          response.json().then((json) => {
            this.results = json.map((j) => {
              return {
                request_id: j.request_id,
                timestamp: dtf.format(new Date(j.timestamp)),
                task: j.task,
                result: JSON.stringify(j.result)
              }
            })
          })
        }
        this.isFetch = true
      })
    },
    // fetchEndPointLog () {
    //   api.get(`/api/services/${this.fiwareService}/endpoints/${this.$route.params.id.split(/:/g).pop()}/log`, null, null, this.$store.state.tokenRPT).then((response) => {
    //     if (response.status === 200) {
    //       let dtf = new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium', timeStyle: 'long' })
    //       response.json().then((json) => {
    //         this.logs = json.map((j) => {
    //           return {
    //             args: j._source.args,
    //             at: dtf.format(new Date(j._source.at)),
    //             params: j._source.params,
    //             payload: j._source.payload
    //           }
    //         })
    //       })
    //     }
    //     this.isFetch = true
    //   })
    // },
    fetchEndPointException () {
      api.get(`/api/services/${this.fiwareService}/endpoints/${this.$route.params.id.split(/:/g).pop()}/exception`, null, null, this.$store.state.tokenRPT).then((response) => {
        if (response.status === 200) {
          let dtf = new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium', timeStyle: 'long' })
          response.json().then((json) => {
            this.exceptions = json.map((j) => {
              return {
                request_id: j.request_id,
                timestamp: dtf.format(new Date(j.timestamp)),
                task: j.task,
                result: j.result
              }
            })
          })
        }
        this.isFetch = true
      })
    },
    // fetchEndPoint: function () {
    //   api.get(`/api/services/${this.fiwareService}/endpoints/${this.$route.params.id.split(/:/g).pop()}`, null, null, this.$store.state.tokenRPT).then((response) => {
    //     if (response.status === 200) {
    //       response.json().then((json) => {
    //         this.test = json
    //       })
    //     }
    //     this.isFetch = true
    //   })
    // },
    fetchEndPointRequestMetric: function () {
      api.get(`/api/services/${this.fiwareService}/endpoints/${this.$route.params.id.split(/:/g).pop()}/request-metric`, null, null, this.$store.state.tokenRPT).then((response) => {
        console.log(response.status)
        if (response.status === 200) {
          response.json().then((json) => {
            this.requestMetric = json.map((j) => JSON.parse(j))
          })
        }
        this.isFetch = true
      })
    },
    confirmDialog: function () {
      if (this.dialogType === 'delete') {
        this.$refs.endpoint.deleteEndPoint()
      } else if (this.dialogType === 'update') {
        this.$refs.endpoint.updateEndPoint()
      }
    },
    reloadEndpoint: function () {
      this.isFetch = false
      // this.fetchEndPoint()
      this.fetchEndPointHistory()
      this.fetchEndPointRequestMetric()
      this.fetchEndPointResult()
      // this.fetchEndPointLog()
      this.fetchEndPointException()
    }
  },
  computed: {
    ...mapGetters(['fiwareService']),
    ty: function () {
      return this.$route.params.id.split(/:/g).pop()
    },
    lineData: function () {
      let dtf = new Intl.DateTimeFormat('en-GB', { timeStyle: 'short' })
      return {
        labels: this.requestMetric.map((m) => dtf.format(new Date(m['timestamp']))),
        datasets: [{
          label: 'Req/min',
          data: this.requestMetric.map((m) => m['value']),
          borderWidth: 1
        }]
      }
    }
  },
  watch: {
    '$store.state.fiwareService': function (val) {
      console.log(this.$store.state.fiwareService)
      // this.$router.back()
      // this.$router.go(-1)
    }
  },
  created () {
    // this.fetchEndPoint()
    this.fetchEndPointHistory()
    this.fetchEndPointRequestMetric()
    this.fetchEndPointResult()
    // this.fetchEndPointLog()
    this.fetchEndPointException()
  }
}
</script>
